import Legend from "../../assets/vectors/legende-0.svg"
import Legend0 from "../../assets/vectors/legende-01.svg"
import Legend1 from "../../assets/vectors/legende-1.svg"
import Legend2 from "../../assets/vectors/legende-2.svg"
import Legend3 from "../../assets/vectors/legende-3.svg"
import Legend4 from "../../assets/vectors/legende-4.svg"
import Legend5 from "../../assets/vectors/legende-5.svg"
import Legend6 from "../../assets/vectors/legende-6.svg"
import Legend7 from "../../assets/vectors/legende-7.svg"
import Legend8 from "../../assets/vectors/legende-8.svg"
import Legend9 from "../../assets/vectors/legende-9.svg"

export const AxonoLegend = [
    {
        "svg": Legend,
        "text": "Plateaux de bureaux loués"
    },
    {
        "svg": Legend0,
        "text": "Plateaux de bureaux disponibles"
    },
    {
        "svg": Legend1,
        "text": "Salle de réunion"
    },
    {
        "svg": Legend2,
        "text": "Circulations verticales"
    },
    {
        "svg": Legend3,
        "text": "Espace de restauration réversible"
    },
    {
        "svg": Legend4,
        "text": "Espaces extérieurs"
    },
    {
        "svg": Legend5,
        "text": "Fitness"
    },
    {
        "svg": Legend6,
        "text": "Sanitaires"
    },
    {
        "svg": Legend7,
        "text": "Hall"
    },
    {
        "svg": Legend8,
        "text": "Parking"
    },
    {
        "svg": Legend9,
        "text": "Locaux techniques"
    }
]
