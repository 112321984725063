import React from "react"
import PropTypes from "prop-types"

const AxonoPlanItem = (props) => (
    <div className={["flex items-center w-full",(props.isOpened ? "" : "justify-center")].join(' ')}>
        <ul className={["flex border-b-2 w-16 sm:w-24 text-sm -mr-1 border-gray-300", (props.isOpened ? "w-full sm:w-full opacity-100" : "opacity-0")].join(' ')}>
            <li className={props.isOpened ? "w-40" : "w-8"}>{props.data.value_1}</li>
            <li className={props.isOpened ? "w-40" : "w-8"}>{props.data.value_2}</li>
            <li className={["text-base font-title pr-12 font-bold",(props.isOpened ? "flex-grow" : "w-8")].join(' ')}>{props.data.title}</li>
        </ul>
        <figure className={["flex-shrink-0", (props.w)].join(' ')}>
            {props.children}
        </figure>
    </div>
)

AxonoPlanItem.defaultProps = {
    w: 'w-56 sm:w-72 mt-2 -mb-14 sm:-mb-16'
}

AxonoPlanItem.propTypes = {
    children: PropTypes.node.isRequired,
    isOpened: PropTypes.bool,
    data: PropTypes.object,
    w: PropTypes.string
}

export default AxonoPlanItem
