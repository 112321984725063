import React, {useEffect} from "react"
import data from "../../../content/agile.json"
import Title from "../ui/Title"
import Container from "../ui/Container"
import A from "../../assets/vectors/A.svg"
import PalierImage from "../images/PalierImage"
import PropTypes from "prop-types"
import LineCircles from "../../assets/vectors/line-circles.svg"
import {Diagonals} from "../ui/Deco"
import {useSpring, animated} from "react-spring"
import _ from "lodash"

const Agile = (props) => {
    const [{ springX }, setSpringX] = useSpring(() => ({
        springX: 0
    }))

    const AnimatedA = animated(A)
    const AnimatedDiagonals = animated(Diagonals)

    useEffect(() => {
        const throttled = _.throttle(() => {
            const zoneTop = document.getElementById(data.id).getBoundingClientRect().top
            setSpringX({
                springX: zoneTop > (window.innerHeight * -1) && zoneTop < (window.innerHeight * 1.5) && window.innerWidth > 600
                    ? zoneTop
                    : 0
            })
        }, 200)
        window.addEventListener('scroll', throttled)
        return () => window.removeEventListener('scroll', throttled)
    })

    const moveImage = springX.interpolate(o => `translateX(${(o * 0.025).toFixed(1)}px)`)
    const moveDiagonals = springX.interpolate(o => `translateY(${(o * 0.05).toFixed(1)}px)`)
    const scaleA = springX.interpolate({
        range: [0, 1000], output: [1.05, 0.95]
    }).interpolate(n => n.toFixed(3))

    return (
        <section id={data.id} className={props.css}>
            <Container css={"relative"}>
                <div
                    className={"relative flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:space-x-4"}>
                    <LineCircles
                        className={"stroke-current absolute -top-16 right-0 transform translate-x-1/2 -translate-y-1/2"}/>
                    <AnimatedDiagonals color="text-primary"
                                       css="h-96 -bottom-28 right-0 sm:w-2/3 md:w-3/5"
                                       style={{ transform: moveDiagonals }}
                    />
                    <Title size={1} css={"relative z-4"}>{data.title}</Title>
                    <div className={"relative w-full md:w-3/5"}>
                        <AnimatedA className={"absolute z-2 h-72 fill-none stroke-current stroke-3 overflow-visible text-primary bottom-0 transform translate-y-1/4 -translate-x-1/2"}
                                   style={{ '--tw-scale-x': scaleA, '--tw-scale-y': scaleA }}
                        />
                        <animated.div style={{ transform: moveImage }} className={"relative pb-3/4 md:pb-3/5"}>
                            <div className={"absolute inset-0 z-1 object-cover"}>
                                <PalierImage/>
                            </div>
                        </animated.div>
                        <p className={"absolute w-64 md:w-96 text-right z-2 leading-loose text-2xl font-medium uppercase right-4 md:right-0 top-full md:top-3/4 transform -translate-y-3/4 md:translate-x-1/3 md:-translate-y-1/2"}>
                            <span className={"break-clone p-1 bg-white"}>
                                {data.subtitle}
                            </span>
                        </p>
                    </div>
                </div>
            </Container>
        </section>
    )
}

Agile.defaultProps = {
    css: ``
}

Agile.propTypes = {
    css: PropTypes.string
}

export default Agile
