import React, {useState} from "react"
import PlanDefaultImage from "../images/PlanDefaultImage"
import PlanCovidImage from "../images/PlanCovidImage"
import OpenSpaceLegende from "./OpenSpaceLegende"
import Modal from "../ui/Modal"
import Plus from "../../assets/vectors/plus.svg"
import Minus from "../../assets/vectors/minus.svg"

import planDefaultHdSrc from "../../images/plan-1-hd.jpg"
import planCovidHdSrc from "../../images/plan-2-hd.jpg"
import {useSpring,animated} from "react-spring"

const plans = [<PlanDefaultImage />, <PlanCovidImage />]
const plansHD = [planDefaultHdSrc, planCovidHdSrc]

const AmenagementPlan = (props) => {
    const [modalOpened, setModalOpened] = useState(false)
    const [zoomLevel, setZoomLevel] = useState(1)
    const [{ springZoomLevel }, setSpringZoomLevel] = useSpring(() => ({
        springZoomLevel: 1
    }))

    function reduceZoomLevel() {
        const set = zoomLevel > 1 ? (zoomLevel - 1) : 1
        setZoomLevel(set)
        setSpringZoomLevel({springZoomLevel: set})
    }

    function increaseZoomLevel() {
        const set = zoomLevel < 5 ? (zoomLevel + 1) : 5
        setZoomLevel(set)
        setSpringZoomLevel({springZoomLevel: set})
    }

    const scaleContainer = springZoomLevel.interpolate({
        range: [1, 5], output: [0.5, 1]
    }).interpolate(n => n.toFixed(1))

    return (
        <>
            <div className={"space-y-8 md:space-y-16"}>
                <button className={"group relative w-full object-cover transform transition hover:-translate-y-px hover:opacity-75 focus:outline-none"}
                        onClick={() => setModalOpened(true)}
                >
                    <svg className={[
                            "absolute z-5 h-12 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-black",
                            "transform transition group-hover:scale-125"
                         ].join(' ')}
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                    </svg>
                    {plans[props.opened]}
                    <span className={"sr-only"}>Voir en plus grand</span>
                </button>
                <OpenSpaceLegende />
            </div>
            <Modal isShowing={modalOpened} hide={() => setModalOpened(false)} theme={"primary"}>
                <div className={"bg-white"}>
                    <div className={"h-screen px-8 py-20 flex items-center justify-center"}>
                        <div className={"relative h-full overflow-auto"}>
                            <animated.picture className={"block transform"}
                                              style={{ '--tw-scale-x': scaleContainer, '--tw-scale-y': scaleContainer }}
                                              data-zoom-level={zoomLevel}
                            >
                                <img src={plansHD[props.opened]}
                                     className={"max-w-none"}
                                     alt={""}
                                />
                            </animated.picture>
                        </div>
                        <ul className={"absolute bottom-6 left-1/2 flex items-center space-x-1 transform -translate-x-1/2"}>
                            <li>
                                <button className={"block w-8 h-8 flex items-center justify-center text-primary border border-primary p-1 hover:opacity-75 focus:outline-none"}
                                        onClick={() => reduceZoomLevel()}
                                >
                                    <Minus className={"w-6 h-6"} />
                                </button>
                            </li>
                            <li>
                                <button className={"block w-8 h-8 flex items-center justify-center text-primary border border-primary p-1 hover:opacity-75 focus:outline-none"}
                                        onClick={() => increaseZoomLevel()}
                                >
                                    <Plus className={"w-6 h-6"} />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AmenagementPlan
