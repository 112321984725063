import PropTypes from "prop-types"
import React from "react"
import Container from "../ui/Container"
import Title from "../ui/Title"
import OpenSpaceItem from "./OpenSpaceItem"
import data from "../../../content/open-space/global.json"

const OpenSpace = (props) => (
    <section id={data.id} className={props.css}>
        <Container css={"space-y-4"}>
            <Title size={2}>
                {data.title}
            </Title>
            <OpenSpaceItem />
        </Container>
    </section>
)

OpenSpace.defaultProps = {
    css: ``
}

OpenSpace.propTypes = {
    css: PropTypes.string
}

export default OpenSpace
