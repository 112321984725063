import PropTypes from "prop-types"
import Parser from "html-react-parser"
import React from "react"
import Dots from "../../assets/vectors/dotted-circle.svg"

const DottedCircle = (props) => (
    <div className={"relative bg-white text-center p-2 flex flex-col justify-center rounded-full text-xs leading-relaxed h-48 w-48"}>
        <p>{Parser(props.data)}</p>
        <Dots className={"absolute text-white h-64 w-64 -left-8 -top-8"} />
    </div>
)

DottedCircle.propTypes = {
    data: PropTypes.string
}

export default DottedCircle
