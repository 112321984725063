import datas from "../../../content/open-space/legende.json"
import React from "react"

const OpenSpaceLegende = () => (
    <ul className={"space-y-4 md:space-y-0 md:grid md:grid-cols-3 gap-4"}>
        {datas.map((item,i) => (
            <li key={i}
                className={["px-2 py-1 text-xs uppercase font-medium flex flex-row items-center space-x-2", item.css].join(' ')}
            >
                <span className={"h-3 w-3 ring-opacity-5 ring-2 ring-black flex-shrink-0 shadow-sm rounded-full"}
                      style={{ backgroundColor: item.color}}
                />
                <span>{item.text}</span>
            </li>
        ))}
    </ul>
)

export default OpenSpaceLegende
