import React from "react"
import SalleImage from "../images/SalleImage"
import Container from "../ui/Container"
import A from "../../assets/vectors/A.svg"
import Title from "../ui/Title"
import PropTypes from "prop-types"
import data from "../../../content/home.json"

const Accueillant = (props) => (
    <section id={data.accueillantId} className={["relative overflow-hidden", props.css].join(' ')}>
        <div className={"relative w-full overflow-hidden pb-2/3 md:pb-1/3 2xl:pb-1/4"}>
            <div className={"absolute inset-0 object-cover object-bottom"}>
                <SalleImage alt="" />
            </div>
        </div>
        <Container css={"absolute w-full h-full inset-0"}>
            <A className={"h-full z-1 absolute -bottom-4 right-4 lg:right-16 transform scale-125 translate-x-1/2 text-gray-100 fill-current xl:scale-150"} />
            <div className={"absolute z-4 right-4 lg:right-16 bottom-8"}>
                <Title css={"text-shadow-lg"} color={"white"} size={1}>
                    {data.accueillant}
                </Title>
            </div>
        </Container>
    </section>
)

Accueillant.defaultProps = {
    css: ``
}

Accueillant.propTypes = {
    css: PropTypes.string
}

export default Accueillant
