import React from "react"
import Layout from "../components/layout/Layout"
import Home from "../components/content/Home"
import useSiteMeta from "../hooks/useSiteMeta"
import SEO from "../components/seo"
import Intro from "../components/content/Intro"

const IndexPage = () => {
    const data = useSiteMeta()

    return (
        <Layout>
            <SEO title={data.meta_title} />
            <Intro />
            <Home />
        </Layout>
    )
}

export default IndexPage
