import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HallBannerImage = ({props}) => {
    const data = useStaticQuery(graphql`
        query {
          placeholderImage: file(relativePath: { eq: "shoootin-photo-21.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2580, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
    `)

    if (!data?.placeholderImage?.childImageSharp?.fluid) {
        return <div>Picture not found</div>
    }

    return <Img fluid={data.placeholderImage.childImageSharp.fluid} {...props} imgStyle={{ objectPosition: "bottom"}} />
}

export default HallBannerImage
