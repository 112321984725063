import React, {useEffect} from "react"
import Container from "../ui/Container"
import HallBasImage from "../images/HallBasImage"
import data from "../../../content/attractif.json"
import Title from "../ui/Title"
import Parser from "html-react-parser"
import {useSpring, animated} from "react-spring"
import _ from "lodash"

const Attractif = () => {
    const zone = data.id
    const parseContent = Parser(data.content)

    const [{ springScrollY }, springSetScrollY] = useSpring(() => ({
        springScrollY: 0
    }))

    useEffect(() => {
        const throttled = _.throttle(() => {
            const zoneTop = document.getElementById(zone).getBoundingClientRect().top
            springSetScrollY({
                springScrollY: zoneTop > (window.innerHeight * -1) && zoneTop < (window.innerHeight * 1.5) && window.innerWidth > 600
                    ? zoneTop
                    : 0
            })
        }, 200)
        window.addEventListener('scroll', throttled)
        return () => window.removeEventListener('scroll', throttled)
    })

    const moveImage = springScrollY.interpolate(o => `translateY(${(o * 0.025).toFixed(1)}px)`)

    return (
        <section id={zone} className={"relative"}>
            <Container css={"flex flex-col items-center md:items-end"}>
                <animated.div style={{ transform: moveImage }} className={"relative z-2 w-full pb-2/3 md:pb-2/5 overflow-hidden"}>
                    <div className={"absolute inset-0 object-cover"}>
                        <HallBasImage alt="" />
                    </div>
                </animated.div>
                <div className={"relative z-2 bg-gray-200 p-8 space-y-4 transform -translate-y-12 sm:w-4/5 md:mr-16 md:p-12 lg:p-16 lg:w-1/2 lg:-mb-24 lg:-translate-y-24 xl:-mb-56 xl:-translate-y-56"}>
                    <Title size={1}>{data.title}</Title>
                    <div className={"space-y-4"}>
                        {parseContent}
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Attractif
