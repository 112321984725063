import React from "react"
import Title from "../ui/Title"
import ListItem from "./ListItem"
import PropTypes from "prop-types"
import AmenagementPlan from "./AmenagementPlan"

const Amenagement = (props) => (
    <article className={["flex flex-col-reverse text-gray-700 space-y-12 space-y-reverse md:space-y-0 md:flex-row md:items-center", props.css].join(' ')}>
        <div className={"md:flex-shrink-0 space-y-8"}>
            <header className={"space-y-4"}>
            <span className={"text-lg text-white uppercase bg-gray-300 font-bold p-2"}>
                {props.data.level}
            </span>
                <Title size={2}>
                    <span className={"text-gray-800 lowercase"}>{props.data.surface}</span>
                </Title>
            </header>
            <div className={"space-y-8 md:space-y-16"}>
                <ul className={"space-y-1 md:space-y-2"}>
                    {props.content.items.map((item,i) => (
                        <ListItem key={i} {...item} css={"text-sm md:text-lg"} />
                    ))}
                    <ListItem {...props.content.total} valueCss={"text-2xl"} textCss={"text-lg uppercase font-title font-bold"} />
                </ul>
                <ul className={"space-y-1 md:space-y-2"}>
                    {props.content.rooms.map((item,i) => (
                        <ListItem key={i} {...item} css={"text-sm md:text-lg"} />
                    ))}
                </ul>
            </div>
        </div>
        <div className={"w-full md:px-12 lg:px-20 xl:pl-32"}>
            <AmenagementPlan opened={props.index} />
        </div>
    </article>
)

Amenagement.defaultProps = {
    css: ``
}

Amenagement.propTypes = {
    css: PropTypes.string
}

export default Amenagement
