import React, {useState} from "react"
import Title from "../ui/Title"
import WindowsTwoImage from "../images/WindowsTwoImage"
import {AxonoLegend} from "./AxonoLegend"
import {useTrail, animated} from "react-spring"
import Plus from "../../assets/vectors/plus.svg"
import data from "../../../content/axono.json"
import AxonoPlan from "./AxonoPlan"
import { useInView } from "react-intersection-observer"
import A from "../../assets/vectors/A.svg"
import Container from "../ui/Container"

const Axono = () => {
    const legendItems = AxonoLegend
    const { ref, inView } = useInView({triggerOnce: true})
    const [isOpened, setIsOpened] = useState(false)
    const legendTrail = useTrail(legendItems.length, {
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(-1rem)',
        from: { opacity: 0, transform: 'translateY(-1rem)' }
    })

    const legendRender = legendTrail.map((props, index) => {
        const item = legendItems[index]
        const Render = item.svg
        return (
            <animated.li key={index} className={"flex space-x-2 items-center"} style={props}>
                <Render />
                <span className={"relative z-2 text-xs uppercase"}>{item.text}</span>
            </animated.li>
        )
    })

    return (
        <section id="plans" className={"md:pb-24 md:pt-32"}>
            <Container>
                <div className={"flex flex-col space-y-12 md:space-y-0 md:flex-row"}>
                    <div className={["flex-shrink-0 flex items-center md:w-1/2 transition-all", (isOpened ? "md:w-3/4" : null)].join(' ')}>
                        <button className={[
                                    "relative z-4 bg-primary transform rounded-full h-14 w-14 p-4 focus:outline-none hover:rotate-24",
                                    (isOpened ? "rotate-45 -translate-x-8 md:-translate-x-4" : "rotate-0")
                                ].join(' ')}
                                onClick={() => setIsOpened(!isOpened)}
                        >
                            <Plus className={"stroke-current text-white h-6 w-6"} />
                            <span className={[
                                "bg-primary -z-1 rounded-full absolute left-0 top-0 absolute h-14 w-14",
                                (!isOpened ? "animate-ping" : "transform opacity-25 scale-125 transition hover:scale-150")].join(' ')
                            }></span>
                            <span className={"sr-only"}>Voir en détail</span>
                        </button>
                        <AxonoPlan isOpened={isOpened} />
                    </div>
                    <div className={"md:w-1/2 flex-shrink-0 space-y-16"}>
                        <div className={"sm:w-96"}>
                            <Title size={2}>
                                <span className={"leading-tight"}>{data.title}</span>
                            </Title>
                        </div>
                        <div className={"relative"}>
                            <div className={"relative pb-3/4"}>
                                <div className={"absolute inset-0 object-cover"}>
                                    <WindowsTwoImage alt="" />
                                </div>
                            </div>
                            <ul ref={ref} className={"bg-gray-200 space-y-4 p-6"}>
                                {legendRender}
                            </ul>
                            <A className={"absolute fill-none stroke-current stroke-2 text-white transform left-16 translate-x-1/2 -bottom-20 w-72 h-72"} />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Axono
