import React, {useEffect} from "react"
import data from "../../../content/dynamique.json"
import Container from "../ui/Container"
import BureauImage from "../images/BureauImage"
import PropTypes from "prop-types"
import LineCircles from "../../assets/vectors/line-circles.svg"
import Title from "../ui/Title";
import {useSpring, animated} from "react-spring"
import _ from "lodash"

const Dynamique = (props) => {
    const [{ scaleSpring }, setScaleSpring] = useSpring(() => ({
        scaleSpring: 0
    }))

    useEffect(() => {
        const throttled = _.throttle(() => {
            const zoneTop = document.getElementById(data.id).getBoundingClientRect().top
            setScaleSpring({
                scaleSpring: zoneTop > (window.innerHeight * -1) && zoneTop < (window.innerHeight * 1.5) && window.innerWidth > 600
                    ? zoneTop
                    : 0
            })
        }, 200)
        window.addEventListener('scroll', throttled)
        return () => window.removeEventListener('scroll', throttled)
    })

    const scaleCircleOne = scaleSpring.interpolate({
        range: [0, 1000], output: [1.025, 0.975]
    }).interpolate(n => n.toFixed(3))

    return (
        <section id={data.id} className={props.css}>
            <Container css={"relative"}>
                <div className={"relative pb-full md:pb-1/2"}>
                    <animated.div className={"absolute inset-0 transform object-cover"}
                         style={{ '--tw-scale-x': scaleCircleOne, '--tw-scale-y': scaleCircleOne }}
                    >
                        <BureauImage />
                    </animated.div>
                    <LineCircles className={"stroke-current absolute -top-0 left-0 transform -translate-x-1/2 -translate-y-1/2"} />
                    <div className={"absolute top-4 sm:top-auto sm:bottom-1/12 transform -translate-x-16 bg-primary leading-none space-y-1 w-48 sm:w-56 md:w-72 p-8 pl-16  md:px-16 text-white"}>
                        <p className={"text-5xl font-bold"}>
                            {data.score}
                        </p>
                        <p>{data.desc}</p>
                    </div>
                </div>
                <div className={"flex relative -mt-12 flex-col items-center md:absolute md:right-1/12 md:bottom-0 transform md:translate-y-1/3"}>
                    <div className={"bg-white text-center p-6 flex flex-col justify-center rounded-full text-xs leading-relaxed h-72 w-72 md:h-88 md:w-88"}>
                        <Title size={2}>
                            <span className={"leading-tight"}>{data.title}</span>
                        </Title>
                    </div>
                </div>
            </Container>
        </section>
    )
}

Dynamique.defaultProps = {
    css: ``
}

Dynamique.propTypes = {
    css: PropTypes.string
}

export default Dynamique
