import React from "react"
import PropTypes from "prop-types"

const ListBlocItem = (props) => (
    <>
        <li className={props.css} style={props.style}>
            <strong className={"block text-3xl lg:text-4xl"}>{props.text}</strong>
            <p className={"text-sm lg:text-base"}>{props.value}</p>
        </li>
    </>
)

ListBlocItem.defaultProps = {
    text: ``,
    value: ``,
    css: ``,
    style: {}
}

ListBlocItem.propTypes = {
    text: PropTypes.string,
    value: PropTypes.string,
    css: PropTypes.string,
    style: PropTypes.object
}

export default ListBlocItem
