import React, {useEffect, useState} from "react"
import Container from "../ui/Container"
import WindowsImage from "../images/WindowsImage"
import useSiteMeta from "../../hooks/useSiteMeta"
import Australia from "../../assets/vectors/australia.svg"
import {useSpring, animated} from "react-spring"
import _ from "lodash"
import A from "../../assets/vectors/A-mask.svg"
import RA from "../../assets/vectors/R-A.svg"
import ArrowDown from "../ui/ArrowDown"

const Intro = () => {
    const data = useSiteMeta()
    const AnimatedA = animated(A)
    const AnimatedRA = animated(RA)
    const AnimatedAustralia = animated(Australia)

    const [clientHeight, setClientHeight] = useState(0)
    const scaleLimit = clientHeight * 2
    const scaleLimitReveal = scaleLimit * 0.9

    const [{ springScrollX }, springSetScrollX] = useSpring(() => ({
        springScrollX: 0
    }))

    useEffect(() => {
        setClientHeight(window.innerHeight)
        const mainHeader = document.getElementById('app-header')

        const throttled = _.throttle(() => {
            if( window.scrollY < scaleLimit ) {
                mainHeader.classList.add("md:opacity-0")
            } else {
                mainHeader.classList.remove("md:opacity-0")
            }

            const value = window.scrollY > scaleLimit ? scaleLimit : window.scrollY
            springSetScrollX({ springScrollX: value })
        }, 200)

        window.addEventListener('scroll', throttled)
        window.addEventListener('resize', throttled)

        return () => {
            window.removeEventListener('scroll', throttled)
            window.removeEventListener('resize', throttled)
        }
    }, [scaleLimit, springSetScrollX])

    useEffect(() => {
        const mainHeader = document.getElementById('app-header')
        mainHeader.classList.add("md:opacity-0")
    }, [])

    const scaleIcon = springScrollX.interpolate({
        range: [0, scaleLimit], output: [1, 3.5]
    }).interpolate(n => n.toFixed(3))

    const scaleRa = springScrollX.interpolate({
        range: [0, scaleLimit], output: [1,8]
    }).interpolate(n => n.toFixed(1))

    const translateRa = springScrollX.interpolate({
        range: [0, scaleLimit], output: [1,25]
    }).interpolate(n => `-${n}vh`)

    const scaleAustralia = springScrollX.interpolate({
        range: [0, scaleLimit], output: [1,1.75]
    }).interpolate(n => n.toFixed(1))

    const revealBg = springScrollX.interpolate({
        range: [0,scaleLimitReveal, scaleLimit],
        output: [1,1,0]
    }).interpolate(n => n.toFixed(2))

    return (
        <div>
            <div style={{ height: "300vh" }}>
                <div className={"h-screen"}>
                    <div className={"fixed inset-0 object-cover"}>
                        <WindowsImage />
                    </div>
                    <animated.div className={"relative z-50 pointer-events-none"} style={{ opacity: revealBg }}>
                        <AnimatedA className={"fixed w-full fill-current text-gray-300 h-screen transform"}
                                   style={{ '--tw-scale-x': scaleIcon, '--tw-scale-y': scaleIcon }}
                        />
                        <animated.div className={"ra-arc-text fixed transform -translate-y-full left-0 top-1/2 w-full"}
                                      style={{ '--tw-scale-x': scaleRa, '--tw-scale-y': scaleRa, marginTop: translateRa }}
                        >
                            <AnimatedRA className={"text-primary transform absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"}
                                        style={{ '--tw-scale-x': scaleIcon, '--tw-scale-y': scaleIcon, '--tw-rotate': '-2.5deg' }}
                            />
                        </animated.div>
                        <div className={"fixed flex flex-col justify-end items-center h-screen w-full bottom-0 transform"}>
                            <AnimatedAustralia className={"text-black australia-black mb-14 fill-current transform -translate-y-full h-8"}
                                       style={{ '--tw-scale-x': scaleAustralia, '--tw-scale-y':scaleAustralia }}
                            />
                            <ArrowDown color={"text-primary"} href={"/#intro"} size={"w-8 h-8"} />
                        </div>
                    </animated.div>
                </div>
            </div>
            <div id={"intro"}>
                <Container css={"relative z-2 text-white text-lg flex flex-col h-screen items-center justify-center space-y-4"}>
                    <Australia className={"fill-current w-full md:w-auto md:h-16"} />
                    <span className={"text-shadow-lg font-medium text-center"}>
                        {data.subtitle}
                    </span>
                    <ArrowDown color={"text-white"} href={"/#repere"} />
                </Container>
            </div>
        </div>
    )
}

export default Intro
