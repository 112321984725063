import data from "../../../content/accessible.json"
import homeData from "../../../content/home.json"

import Title from "../ui/Title"
import AccessDetail from "./AccessDetail"
import AccessMapImage from "../images/AccessMapImage"
import React from "react"
import {useInView} from "react-intersection-observer"
import {animated, useSpring} from "react-spring"

const Access = () => {
    const { ref, inView } = useInView({ triggerOnce: true })

    const AnimatedTitle = animated(Title)
    const titleStyle = useSpring({
        opacity: inView ? 1 : 0,
        delay: 500
    })

    return (
        <div id={data.id} className={"flex flex-col-reverse items-center md:flex-row lg:pb-24"}>
            <div className={"relative z-1 md:w-1/3 md:flex-shrink-0 space-y-16 pt-4 pb-20 md:pt-24"}>
                <div className={"space-y-4"}>
                    <AnimatedTitle style={titleStyle} size={2} css="md:-mr-32 lg:-mr-12">
                        {data.title}
                    </AnimatedTitle>
                    <p className={"text-sm leading-relaxed"}>{data.description}</p>
                    {homeData.guideSrc &&
                        <p className={"text-xs leading-relaxed sm:w-64"}>
                            <a href={homeData.guideSrc}
                               target={"_blank"}
                               rel={"noopener noreferrer"}
                               className={"font-bold text-primary uppercase underline-offset-2 underline-primary-2 hover:text-black hover:underline-black-2"}
                            >
                                {homeData.guide}
                            </a>
                        </p>
                    }
                </div>
                <div ref={ref}>
                    <AccessDetail />
                </div>
            </div>
            <div className={"relative pb-48 sm:pb-28 md:pb-16 lg:pb-0 w-full -mt-6 lg:-ml-16 lg:-mr-12 xl:-ml-24 xl:-mr-20"}>
                <AccessMapImage />
                <a href={data.url}
                   className={"absolute bottom-40 sm:bottom-24 md:bottom-8 xl:ml-8 left-2/5 h-32 w-56"}
                   target={"_blank"} rel={"noopener noreferrer"}
                >
                    <span className={"sr-only"}>Voir sur Google Maps</span>
                </a>
                <div className={"absolute bottom-8 -right-8 flex flex-col py-8 px-12 bg-white shadow-sm text-lg xl:bottom-16 xl:py-10 xl:px-16 2xl:-right-16"}>
                    <strong className={"text-3xl -ml-px md:text-5xl lowercase xl:text-6xl"}>{data.duration}</strong>
                    <span>{data.place}</span>
                </div>
            </div>
        </div>
    )
}

export default Access
