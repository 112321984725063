import React from "react"
import PropTypes from "prop-types"
import Sens from "./Sens"
import Boost from "./Boost"

const Outro = (props) => (
    <section className={["overflow-hidden space-y-24 xl:space-y-0", props.css].join(' ')}>
        <Sens />
        <Boost />
    </section>
)

Outro.defaultProps = {
    css: ``
}

Outro.propTypes = {
    css: PropTypes.string
}

export default Outro
