import React, {useEffect} from "react"
import Container from "../ui/Container"
import data from "../../../content/repere.json"
import {animated, useSpring} from "react-spring"
import _ from "lodash"
import RepereContent from "./RepereContent"
import BuildingAltImage from "../images/BuildingAltImage"

const Repere = ({css}) => {
    const [{ springScrollY }, springSetScrollY] = useSpring(() => ({
        springScrollY: 0
    }))
    const moveImage = springScrollY.interpolate(o => `translateY(${(o * 0.075).toFixed(1)}px)`)

    useEffect(() => {
        const throttled = _.throttle(() => {
            const zoneTop = document.getElementById('repere').getBoundingClientRect().top
            springSetScrollY({
                springScrollY: zoneTop > (window.innerHeight * -1) && zoneTop < (window.innerHeight * 1.5) && window.innerWidth > 600
                    ? zoneTop
                    : 0
            })
        }, 200)
        window.addEventListener('scroll', throttled)
        return () => window.removeEventListener('scroll', throttled)
    })

    return (
        <section id="repere" className={css}>
            <Container css={"flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-8"}>
                <div className={"-ml-8 w-full md:ml-0 md:w-2/3"}>
                    <animated.div style={{ transform: moveImage }} className={"relative w-full pb-full md:pb-2/3 overflow-hidden"}>
                        <div className={"absolute inset-0 object-cover"}>
                            <BuildingAltImage alt={data.title} />
                        </div>
                    </animated.div>
                </div>
                <RepereContent />
            </Container>
        </section>
    )
}

export default Repere
