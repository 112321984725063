import React, {useState} from "react"
import data from "../../../content/open-space/global.json"
import defaultData from "../../../content/open-space/default.json"
import covidData from "../../../content/open-space/covid.json"
import Amenagement from "./Amenagement"

const OpenSpaceItem = () =>  {
    const [opened,setOpened] = useState(0)
    const datas = [defaultData, covidData]

    return (
        <div className={"space-y-12 md:space-y-8"}>
            <ul data-opened={opened} className={"flex flex-col sm:flex-wrap sm:items-center sm:flex-row md:flex-nowrap"}>
                {datas.map((item,i) => (
                    <li className={"flex-shrink-0"} key={i}>
                        <button className={[
                                    "w-full whitespace-nowrap cursor-pointer border flex-shrink-0 border-primary py-2 px-4 -mr-px uppercase font-medium focus:outline-none",
                                    (i === opened ? 'bg-primary hover:text-white' : 'hover:text-primary')
                                ].join(' ')}
                                onClick={() => setOpened(i)}
                                onKeyDown={() => setOpened(i)}
                        >
                            {item.name}
                        </button>
                    </li>
                ))}
                {datas.map((item,i) => {
                    const description = item.desc ? item.desc : null
                    if( description && (i === opened) ) {
                        return (
                            <li className={"w-full md:w-auto border text-xs lg:text-base leading-tight border-transparent uppercase font-medium py-4 md:py-2 md:px-4"}
                                key={i}
                            >
                                {description}
                            </li>
                        )
                    }
                    return(null)
                })}
            </ul>
            <Amenagement data={data} index={opened} content={datas[opened]} />
        </div>
    )
}

export default OpenSpaceItem
