import train from "../../../content/access/train.json"
import voiture from "../../../content/access/voiture.json"
import AccessItem from "./AccessItem"
import React from "react"
import Title from "../ui/Title"
import IconTrain from "../../assets/vectors/icon-train.svg"
import IconCar from "../../assets/vectors/icon-car.svg"
import PropTypes from "prop-types"
import {useSpring, animated} from "react-spring"
import {useInView} from "react-intersection-observer"

const AccessDetail = ({css}) => {
    const { ref, inView } = useInView({ triggerOnce: true })
    const firstItem = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(-1rem)',
        delay: 750
    })
    const secondItem = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(-1rem)',
        delay: 1000
    })

    return (
        <section ref={ref} className={["space-y-12", css].join(' ')}>
            <animated.div style={firstItem}>
                <IconTrain className={"stroke-current text-gray-300 transform -translate-x-1/2 -ml-1 mb-4"}/>
                <Title size={4} css={"mb-2 md:mb-0"}>{train.title}</Title>
                <AccessItem data={train.items}/>
            </animated.div>
            <animated.div style={secondItem}>
                <IconCar className={"stroke-current text-gray-300 transform -translate-x-1/2 ml-2 mb-4"}/>
                <Title size={4} css={"mb-2 md:mb-0"}>{voiture.title}</Title>
                <AccessItem data={voiture.items}/>
            </animated.div>
        </section>
    )
}

AccessDetail.defaultProps = {
    css: ``
}

AccessDetail.propTypes = {
    css: PropTypes.string
}

export default AccessDetail
