import CarousselImage from "../images/CarousselImage"
import Title from "../ui/Title"
import data from "../../../content/sens.json"
import ListBlocItem from "./ListBlocItem"
import Container from "../ui/Container"
import React, {useEffect} from "react"
import {Diagonals} from "../ui/Deco"
import Circles from "../../assets/vectors/dotted-circle-alt.svg"
import {useSpring, animated} from "react-spring"
import _ from "lodash"

const Sens = () => {
    const [{ parallaxY }, setParallaxY] = useSpring(() => ({
        parallaxY: 0
    }))
    const translateY = parallaxY.interpolate(o => `translateY(${(-1 * (o * 0.04)).toFixed(1)}px)`)

    useEffect(() => {
        const throttled = _.throttle(() => {
            const zoneTop = document.getElementById(data.id).getBoundingClientRect().top
            setParallaxY({
                parallaxY: zoneTop > (window.innerHeight * -1) && zoneTop < (window.innerHeight * 1.5) && window.innerWidth > 600
                    ? zoneTop
                    : 0
            })
        }, 200)
        window.addEventListener('scroll', throttled)
        return () => window.removeEventListener('scroll', throttled)
    })

    return (
        <Container id={data.id} css={"relative z-2 md:flex md:flex-row-reverse items-center md:py-16 xl:py-24"}>
            <Diagonals color="text-white" css={"md:bottom-0 z-3 h-32 md:h-64 sm:w-2/3 md:w-1/3 xl:translate-x-24 xl:-translate-y-16"} />
            <div className={"relative transform w-full -ml-8 md:ml-0 md:-mr-16 md:-translate-y-16 lg:-translate-y-24 xl:-mr-24"}>
                <Circles className={"absolute z-2 text-primary left-0 top-1/2 mt-32 transform -translate-x-1/2 -translate-y-1/2"} />
                <animated.div style={{ transform: translateY }} className={"relative z-3 pb-2/3"}>
                    <div className={"absolute inset-0 object-cover"}>
                        <CarousselImage />
                    </div>
                </animated.div>
            </div>
            <div className={"flex flex-col space-y-4 pl-8 md:space-y-12 transform md:items-end md:text-right md:pl-0 md:pr-12 md:w-1/2 lg:w-1/3 md:translate-y-16 lg:translate-y-24"}>
                <Title css={"pt-8 md:pt-0 sm:w-72 relative z-4"} size={2}>
                    {data.title}
                </Title>
                <ul className={"flex-grow flex flex-col justify-center relative z-4 bg-gray-100 p-8 space-y-6 md:px-12 md:py-20 md:-mr-28 xl:-mr-40"}>
                    {data.items.map((item, i) => (
                        <ListBlocItem key={i} {...item} />
                    ))}
                </ul>
            </div>
        </Container>
    )
}

export default Sens
