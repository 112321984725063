import React from "react"
import PropTypes from "prop-types"

const AccessItem = ({data}) => {

    return (
        <ul className={"space-y-1 md:space-y-0"}>
            {data.map((item, index) => {
                return (
                    <li key={index} className={"flex items-center space-x-2 text-sm md:text-base"}>
                        <strong>{item.duration}</strong>
                        <span>{item.place}</span>
                    </li>
                )
            })}
        </ul>
    )
}

AccessItem.propTypes = {
    data: PropTypes.array
}

export default AccessItem
