import {Diagonals} from "../ui/Deco"
import WellnessImage from "../images/WellnessImage"
import Title from "../ui/Title"
import data from "../../../content/boost.json"
import ListBlocItem from "./ListBlocItem"
import Container from "../ui/Container"
import React, {useEffect} from "react"
import {useSpring, animated} from "react-spring"
import _ from "lodash"

const Boost = () => {
    const [{ parallaxY }, setParallaxY] = useSpring(() => ({
        parallaxY: 0
    }))
    const translateY = parallaxY.interpolate(o => `translateY(${(o * 0.04).toFixed(1)}px)`)

    useEffect(() => {
        const throttled = _.throttle(() => {
            const zoneTop = document.getElementById(data.id).getBoundingClientRect().top
            setParallaxY({
                parallaxY: zoneTop > (window.innerHeight * -1) && zoneTop < (window.innerHeight * 1.5) && window.innerWidth > 600
                    ? zoneTop
                    : 0
            })
        }, 200)
        window.addEventListener('scroll', throttled)
        return () => window.removeEventListener('scroll', throttled)
    })

    return (
        <Container id={data.id} css={"relative md:flex md:items-end"}>
            <Diagonals color="text-primary" css="w-full right-8 -top-8 h-32 md:right-auto md:h-96 md:top-auto md:w-3/5 md:-bottom-8" />
            <div className={"w-full relative z-3 -ml-8 md:-ml-16 md:pb-24 xl:-ml-24"}>
                <animated.div style={{ transform: translateY }} className={"relative pb-3/4 md:pb-3/5"}>
                    <div className={"absolute inset-0 object-cover"}>
                        <WellnessImage />
                    </div>
                </animated.div>
            </div>
            <div className={"flex flex-col flex-grow-0 flex-shrink-0 pl-8 md:pl-0 md:pr-16 xl:pr-24 space-y-4"}>
                <div className={"relative z-5 bg-gray-100 p-8 md:-ml-20 md:p-12 md:text-right lg:py-24"}>
                    <div className={"space-y-6"}>
                        <Title size={2} css={""}>
                            {data.title.split("\n").map((i,key) => {
                                return <span className={"md:block"} key={key}>{i}</span>
                            })}
                        </Title>
                        <ul className={"flex-grow flex flex-col justify-center"}>
                            {data.items.map((item, i) => (
                                <ListBlocItem key={i} {...item} css={"flex flex-col-reverse"} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Boost
