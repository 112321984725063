import HallImage from "../images/HallImage"
import React, {useEffect, useState} from "react"
import Container from "../ui/Container"
import data from "../../../content/hall.json"
import {Diagonals} from "../ui/Deco"
import A from "../../assets/vectors/A.svg"
import PropTypes from "prop-types"
import {useSpring, animated} from "react-spring"
import _ from "lodash"
import Modal from "../ui/Modal"

const HallInformation = (props) => {
    const hallData = data
    const zone = "hall-info"
    const [{springScrollY}, springSetScrollY] = useSpring(() => ({ springScrollY: 0}))
    const [zoomed, setZoomed] = useState(false)

    useEffect(() => {
        const throttled = _.throttle(() => {
            const zoneTop = document.getElementById(zone).getBoundingClientRect().top
            springSetScrollY({
                springScrollY: zoneTop > (window.innerHeight * -1) && zoneTop < (window.innerHeight * 1.5) && window.innerWidth > 600
                    ? zoneTop
                    : 0
            })
        }, 200)
        window.addEventListener('scroll', throttled)
        return () => window.removeEventListener('scroll', throttled)
    })

    const moveImage = springScrollY.interpolate(o => `translateY(${(o * 0.05).toFixed(1)}px)`)

    return (
        <>
            <Container css={["relative md:flex", props.css].join(' ')}>
                <A className={"absolute h-96 md:h-full fill-current -mt-12 text-gray-200 md:text-gray-100 ml-4 md:ml-16 transform bottom-12 scale-125 -translate-x-1/2 md:-translate-y-16 md:scale-150 md:bottom-auto"} />
                <Diagonals color="text-primary" css="h-96 sm:w-2/3 md:w-1/2 md:-translate-y-12 xl:-translate-y-24" />
                <div className={"relative z-2 transform md:w-1/2 lg:w-2/3 md:translate-x-12 xl:translate-x-24"}>
                    <animated.button className={"block w-full focus:outline-none"} onClick={() => setZoomed(true)} style={{ transform: moveImage }}>
                        <HallImage />
                    </animated.button>
                </div>
                <ul id={zone}
                    className={"bg-gray-100 relative flex px-6 py-14 flex-col md:mt-12 items-center justify-center space-y-14 md:w-1/2 lg::w-1/3 md:translate-y-6"}
                >
                    {hallData.map((item,i) => (
                        <li key={i} className={"flex w-48 flex-col items-start"}>
                            <span className={"text-lg bg-black mb-1 leading-tight text-white px-1"}>
                                {item.title}
                            </span>
                            <strong className={"leading-tight"}>
                                {item.subtitle}
                            </strong>
                            <span>{item.desc}</span>
                        </li>
                    ))}
                </ul>
            </Container>
            <Modal isShowing={zoomed} hide={() => setZoomed(false)}>
                <div className={"absolute overflow-x-auto inset-0 bg-black bg-opacity-75 flex items-center justify-center"}>
                    <div className={"relative min-w-screen-xl w-full"}>
                        <Container css={"pl-0 pr-0 lg:pl-0 lg:pr-0"}>
                            <HallImage />
                        </Container>
                    </div>
                </div>
            </Modal>
        </>
    )
}

HallInformation.defaultProps = {
    css: ``
}

HallInformation.propTypes = {
    css: PropTypes.string
}

export default HallInformation
