import {animated, useSpring, useTrail} from "react-spring"
import Title from "../ui/Title"
import {useInView} from "react-intersection-observer"
import ListBlocItem from "./ListBlocItem"
import data from "../../../content/repere.json"
import React from "react"

const RepereContent = () => {
    const { ref, inView } = useInView({ triggerOnce: true })

    const AnimatedTitle = animated(Title)
    const titleStyle = useSpring({
        opacity: inView ? 1 : 0,
        delay: 250
    })

    const AnimatedListBlocItem = animated(ListBlocItem)
    const listTrail = useTrail(data.items.length, {
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(-1rem)',
        from: { opacity: 0, transform: 'translateY(-1rem)' },
        delay: 500
    })

    return (
        <div className={"flex flex-col space-y-4 pl-8 sm:pl-16 md:pl-0 md:w-1/3 lg:pr-12 xl:pr-32"}>
            <AnimatedTitle size={2} style={titleStyle}>
                {data.title}
            </AnimatedTitle>
            <ul ref={ref}
                className={"flex-grow flex flex-col justify-center relative z-3 bg-gray-100 p-8 space-y-6 md:-ml-16 md:px-16 lg:py-16 lg:-ml-24 xl:py-20 xl:space-y-16 xl:-ml-40"}
            >
                {listTrail.map((props,i) => {
                    const item = data.items[i]
                    return (
                        <AnimatedListBlocItem key={i} {...item} style={props} />
                    )
                })}
            </ul>
        </div>
    )
}

export default RepereContent
