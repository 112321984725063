import Arrow from "../../assets/vectors/arrow-down.svg"
import React from "react"
import PropTypes from "prop-types"
import data from "../../../content/home.json"

const ArrowDown = (props) => (
    <a className={["animate-bounce pointer-events-auto", props.css].join(' ')}
       href={props.href}
    >
        <Arrow aria-hidden={true}
               className={["fill-current", props.color, props.size].join(' ')}
        />
        <span className={"sr-only"}>
            {props.text}
        </span>
    </a>
)

ArrowDown.defaultProps = {
    css: `absolute bottom-6`,
    color: `text-black`,
    href: `#`,
    size: `w-14 h-14`,
    text: data.more
}

ArrowDown.propTypes = {
    css: PropTypes.string,
    color: PropTypes.string,
    href: PropTypes.string,
    size: PropTypes.string,
    text: PropTypes.string
}

export default ArrowDown
