import PatioImage from "../images/PatioImage"
import React from "react"
import data from "../../../content/patio.json"
import Marker from "../../assets/vectors/marker.svg"
import DottedCircle from "../ui/DottedCircle"

const Patio = () => (
    <section id={data.id} className={"relative"}>
        <div className={"relative pb-full md:pb-3/5 xl:pb-2/4 2xl:pb-2/5"}>
            <div className={"absolute inset-0 object-cover"}>
                <PatioImage />
            </div>
        </div>
        <div className={"absolute inset-0 z-2"}>
            <div className={"absolute z-1 left-0 md:left-1/12 bottom-1/12 md:bottom-1/6 flex flex-col items-center justify-center"}>
                <DottedCircle data={data.text_1} />
                <Marker className={"overflow-visible -mt-20 md:-mt-8 transform -translate-x-full scale-x-reverse md:translate-x-0 md:scale-x-100"}/>
            </div>
            <div className={"absolute z-1 right-0 -mt-12 sm:right-1/12 md:mt-0 md:right-2/6 bottom-2/6 flex flex-col items-center justify-center"}>
                <DottedCircle data={data.text_2} />
                <Marker className={"overflow-visible transform -translate-x-full scale-x-reverse"}/>
            </div>
        </div>
    </section>
)

export default Patio
