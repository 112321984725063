import AxonoRdcImage from "../../images/Axono-RDC-hd.png"
import AxonoRnImage from "../../images/Axono-layer-hd.svg"
import AxonoRnImageNew from "../../images/axono-layer-new-hd.svg"
import AxonoRnImageHalf from "../../images/axono-layer-new-hd-half.svg"

import React from "react"
import PropTypes from "prop-types"
import data from "../../../content/axono.json"
import AxonoPlanItem from "./AxonoPlanItem"

const AxonoPlan = (props) => (
    <div className={["flex flex-col-reverse items-center w-full -ml-14 sm:px-8 lg:px-16",(props.isOpened ? "pl-8" : null)].join(' ')}>
        <ul className={["flex w-24 -mt-12 font-bold text-primary", (props.isOpened ? "w-full opacity-100" : "opacity-0")].join(' ')}>
            {data.columns.map((item,i) => (
                <li key={i} className={props.isOpened ? "w-40" : "w-8"}>{item.value}</li>
            ))}
        </ul>
        <AxonoPlanItem
            data={data.rdc}
            isOpened={props.isOpened}
            w={[
                "transform w-80 -translate-x-2 -translate-y-4 sm:-translate-y-6 sm:w-96",
                (props.isOpened ? "sm:-translate-x-16 sm:-ml-2 xl:-translate-x-0" : "sm:-translate-x-2 sm:-ml-px")
            ].join(' ')}
        >
            <img className={"w-full relative z-0"} src={AxonoRdcImage} alt={data.rdc.title} />
        </AxonoPlanItem>
        <AxonoPlanItem data={data.r1} isOpened={props.isOpened}>
            <img className={"w-full relative z-1"} src={AxonoRnImageHalf} alt={data.r1.title} />
        </AxonoPlanItem>
        {data.rN.map((item,i) => (
            <AxonoPlanItem key={i} data={item} isOpened={props.isOpened}>
                <img className={"w-full relative z-1"}
                     src={item.style === "old" ? AxonoRnImage : (item.style === "half" ? AxonoRnImageHalf : AxonoRnImageNew)}
                     alt={["Étage", item.title].join(' ')}
                />
            </AxonoPlanItem>
        ))}
        <ul className={["flex uppercase font-title w-24 text-sm", (props.isOpened ? "w-full opacity-100" : "opacity-0")].join(' ')}>
            {data.columns.map((item,i) => (
                <li key={i} className={props.isOpened ? "w-40" : "w-8"}>{item.label}</li>
            ))}
        </ul>
    </div>
)

AxonoPlan.propTypes = {
    isOpened: PropTypes.bool
}

export default AxonoPlan
