import React from "react"
import Repere from "./Repere"
import Accessible from "./Accessible"
import Attractif from "./Attractif"
import HallInformation from "./HallInformation"
import Label from "./Label"
import Patio from "./Patio"
import Agile from "./Agile"
import Dynamique from "./Dynamique"
import OpenSpace from "./OpenSpace"
import Accueillant from "./Accueillant"
import Outro from "./Outro"
import Axono from "./Axono"

const Home = () => (
    <div className={"relative bg-white md:z-60 pt-px"}>
        <Repere css={"pb-36 pt-24 md:mt-32"} />
        <Accessible css={"pb-64 -mb-64"} />
        <Attractif />
        <Axono />
        <HallInformation css={"py-32"} />
        <Label />
        <Patio />
        <Agile css={"bg-gray-100 py-32 md:py-32"} />
        <Dynamique css={"bg-gray-100 pt-16 -my-px md:pb-24"} />
        <OpenSpace css={"bg-gray-100 py-24 md:py-32"} />
        <Accueillant />
        <Outro css={"py-32 md:py-48"} />
    </div>
)

export default Home
