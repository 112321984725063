import React from "react"
import Container from "../ui/Container";
import LogoOne from "../../assets/vectors/logo-hqe.svg"
import LogoTwo from "../../assets/vectors/logo-breeam.svg"
import LogoThree from "../../assets/vectors/logo-effinergie.svg"
import data from "../../../content/label.json"

const Label = () => (
    <aside className={"bg-primary relative py-24 sm:py-16 text-white"}>
        <Container css={"flex pb-2 flex-col items-center justify-center space-y-8"}>
            <strong className={"uppercase"}>{data.title}</strong>
            <div className={"flex flex-col space-y-8 sm:space-y-0 sm:flex-row items-center sm:space-x-8 md:space-x-12"}>
                <figure>
                    <LogoOne />
                </figure>
                <figure>
                    <LogoTwo />
                </figure>
                <figure>
                    <LogoThree />
                </figure>
            </div>
        </Container>
    </aside>
)

export default Label
