import React from "react"
import PropTypes from "prop-types"

const ListItem = (props) => (
    <>
        <li className={["flex items-center space-x-4", props.css].join(' ')}>
            <strong className={["text-right font-title min-w-10", props.valueCss].join(' ')}>
                {props.value}
            </strong>
            <span className={props.textCss}>
                {props.text}
            </span>
        </li>
    </>
)

ListItem.defaultProps = {
    text: ``,
    value: ``,
    css: ``,
    textCss: ``,
    valueCss: `text-xl`
}

ListItem.propTypes = {
    text: PropTypes.string,
    value: PropTypes.string,
    css: PropTypes.string,
    textCss: PropTypes.string,
    valueCss: PropTypes.string
}

export default ListItem
