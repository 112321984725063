import React from "react"
import Title from "../ui/Title"
import Container from "../ui/Container"
import A from "../../assets/vectors/A.svg"
import data from "../../../content/accessible.json"
import PropTypes from "prop-types"
import Access from "./Access"
import {useInView} from "react-intersection-observer"
import {animated, useSpring} from "react-spring"
import HallBannerImage from "../images/HallBannerImage"

const Accessible = (props) => {
    const { ref, inView } = useInView({ triggerOnce: true })

    const AnimatedA = animated(A)
    const AnimatedTitle = animated(Title)
    const aStyle = useSpring({
        opacity: inView ? 1 : 0,
        delay: 250
    })
    const titleStyle = useSpring({
        opacity: inView ? 1 : 0,
        delay: 500
    })

    return (
        <section className={["relative bg-gray-100", props.css].join(' ')}>
            <div className={"relative z-2 w-full pb-2/3 md:pb-1/5 overflow-hidden"}>
                <div className={"absolute inset-0 object-cover object-bottom"}>
                    <HallBannerImage alt=""/>
                </div>
            </div>
            <div ref={ref}>
                <Container>
                    <div className={"absolute z-4 -top-8"}>
                        <AnimatedA style={aStyle}
                                   className={"w-48 h-48 -top-3 md:top-0 -left-16 md:-left-3 absolute transform translate-x-px md:-translate-x-px -translate-y-11 text-primary stroke-current fill-none stroke-2"}
                        />
                        <AnimatedTitle style={titleStyle}
                                       css={"pl-3 md:pl-14 text-shadow-lg ml-px"}
                                       color={"primary"}
                                       size={1}
                        >
                            {data.bannerTitle}
                        </AnimatedTitle>
                    </div>
                    <Access/>
                </Container>
            </div>
        </section>
    )
}

Accessible.defaultProps = {
    css: ``
}

Accessible.propTypes = {
    css: PropTypes.string
}

export default Accessible
